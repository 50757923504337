import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-3" }
const _hoisted_3 = { class: "card pt-4 mb-xl-9" }
const _hoisted_4 = { class: "card-header border-0" }
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = {
  key: 0,
  class: "fw-bolder"
}
const _hoisted_7 = { class: "text-primary" }
const _hoisted_8 = { class: "card-body pt-0" }
const _hoisted_9 = { class: "fw-bolder fs-2" }
const _hoisted_10 = { class: "fs-7 fw-normal text-muted" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-12" }
const _hoisted_13 = { class: "card pt-4 mb-xl-9" }
const _hoisted_14 = { class: "card-body" }
const _hoisted_15 = {
  class: "table-responsive",
  id: "printableTable"
}
const _hoisted_16 = { class: "table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" }
const _hoisted_17 = { class: "fw-bolder text-muted" }
const _hoisted_18 = { class: "min-w-120px" }
const _hoisted_19 = { class: "min-w-120px" }
const _hoisted_20 = { class: "min-w-120px" }
const _hoisted_21 = { class: "min-w-120px" }
const _hoisted_22 = { class: "min-w-120px" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_25 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_26 = { class: "text-dark fw-bolder text-hover-primary mb-1 fs-6" }
const _hoisted_27 = { class: "text-dark fw-bolder text-hover-primary mb-1 fs-6" }
const _hoisted_28 = { class: "text-dark fw-bolder text-hover-primary mb-1 fs-6" }
const _hoisted_29 = {
  key: 0,
  class: "badge-light-primary badge text-default"
}
const _hoisted_30 = {
  key: 1,
  class: "badge-light-success badge text-success"
}
const _hoisted_31 = {
  key: 2,
  class: "badge-light-warning badge text-warning"
}
const _hoisted_32 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (this.user && this.user.userprofile)
                ? (_openBlock(), _createElementBlock("h2", _hoisted_6, [
                    _createTextVNode(_toDisplayString(_ctx.$t("pages.roadmap.userRoadmaps")) + " ", 1),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(this.user.userprofile.lastname) + " " + _toDisplayString(this.user.userprofile.firstname), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("pages.roadmap.desc")), 1)
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("table", _hoisted_16, [
                _createElementVNode("thead", null, [
                  _createElementVNode("tr", _hoisted_17, [
                    _createElementVNode("th", _hoisted_18, _toDisplayString(_ctx.$t("pages.roadmap.action")), 1),
                    _createElementVNode("th", _hoisted_19, _toDisplayString(_ctx.$t("pages.roadmap.deadline")), 1),
                    _createElementVNode("th", _hoisted_20, _toDisplayString(_ctx.$t("pages.roadmap.contributor")), 1),
                    _createElementVNode("th", _hoisted_21, _toDisplayString(_ctx.$t("pages.roadmap.impact")), 1),
                    _createElementVNode("th", _hoisted_22, _toDisplayString(_ctx.$t("pages.roadmap.status")), 1)
                  ])
                ]),
                (!_ctx.loading)
                  ? (_openBlock(), _createElementBlock("tbody", _hoisted_23, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roadmaps, (action) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: action.id
                        }, [
                          _createElementVNode("td", null, [
                            _createElementVNode("span", _hoisted_24, _toDisplayString(action.category.label), 1),
                            _createElementVNode("a", _hoisted_25, _toDisplayString(action.label), 1)
                          ]),
                          _createElementVNode("td", _hoisted_26, _toDisplayString(_ctx.$moment(action.deadline).format("DD/MM/YYYY")), 1),
                          _createElementVNode("td", _hoisted_27, _toDisplayString(action.collaborators), 1),
                          _createElementVNode("td", _hoisted_28, _toDisplayString(action.impact), 1),
                          _createElementVNode("td", null, [
                            (action.status === 'TODO')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(_ctx.$t("pages.roadmap.todo")), 1))
                              : (action.status === 'DONE')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(_ctx.$t("pages.roadmap.done")), 1))
                                : (action.status === 'INPR')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(_ctx.$t("pages.roadmap.inProgress")), 1))
                                  : _createCommentVNode("", true)
                          ])
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_32, _cache[0] || (_cache[0] = [
                    _createElementVNode("div", { class: "d-flex row-auto flex-center w-100 h-200px" }, [
                      _createElementVNode("span", {
                        class: "spinner-border text-primary",
                        role: "status"
                      })
                    ], -1)
                  ])))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ])
  ], 64))
}